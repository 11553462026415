var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTableAdvance',{staticClass:"mb-4",attrs:{"items":_vm.banks,"fields":_vm.fields,"store":"accounting.banks","resource":"accounting/card","creatable":"","noFilter":"","title":"Bank"},on:{"click-create":function($event){_vm.showModalCreateBank = true}},scopedSlots:_vm._u([{key:"append-actions",fn:function(){return [_c('CCol',{staticClass:"pl-1 pr-1 ml-1",attrs:{"col":"12"}},[_c('TButtonEdit',{on:{"click":function($event){_vm.showModalEditBank = true}}})],1)]},proxy:true},{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.id,"noTranslate":""}})],1)]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.name,"noTranslate":""}})],1)]}}])}),_c('TTableAdvance',{staticClass:"mb-4",attrs:{"items":_vm.currencies,"fields":_vm.currencyFields,"store":"accounting.currencies","resource":"","noFilter":"","title":"Currency","creatable":""},on:{"click-create":function($event){_vm.showModalCreateCurrency = true}},scopedSlots:_vm._u([{key:"append-actions",fn:function(){return [_c('CCol',{staticClass:"pl-1 pr-1 ml-1",attrs:{"col":"12"}},[_c('TButtonEdit',{on:{"click":function($event){_vm.showModalEditCurrency = true}}})],1)]},proxy:true},{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.id,"noTranslate":""}})],1)]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.name,"noTranslate":""}})],1)]}},{key:"symbol",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.symbol,"noTranslate":""}})],1)]}}])}),_c('BankModal',{attrs:{"show":_vm.showModalCreateBank},on:{"update:show":function($event){_vm.showModalCreateBank=$event}}}),_c('BankModal',{attrs:{"show":_vm.showModalEditBank,"bank":_vm.bank},on:{"update:show":function($event){_vm.showModalEditBank=$event}}}),_c('CurrencyModal',{attrs:{"show":_vm.showModalCreateCurrency},on:{"update:show":function($event){_vm.showModalCreateCurrency=$event}}}),_c('CurrencyModal',{attrs:{"show":_vm.showModalEditCurrency,"currency":_vm.currency},on:{"update:show":function($event){_vm.showModalEditCurrency=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }